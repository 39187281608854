import React from 'react'
import { Helmet } from "react-helmet"
// const MTAEoY2022Content = React.lazy(() => import('../components/MTAEoY2022Content'));
import loadable from '@loadable/component'
const MTAEoY2022Content = loadable(() => import('../components/MTAEoY2022Content'))

const MTAEoY2022 = () => {

  return (
    <>
      <Helmet>
        <title>MTA + Alopex 2022 Year in Review</title>
        <link rel="stylesheet" href="https://use.typekit.net/hoh4grq.css"></link>
      </Helmet>
      <MTAEoY2022Content />
    </>
  )
}

export default MTAEoY2022